import React from "react"
import { useImage } from "../../../hooks/useImage"

export const withStartSection = Component => ({ name = "StartSection", item }) => {
  const { getFluidImage } = useImage()
  const { title, content, image: originalImage, badge } = item

  const image = getFluidImage(originalImage, { maxWidth: 1440 })
  Component.displayName = name
  return <Component title={title} content={content} image={image} badge={badge} />
}
