import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  relative pb-2-4 md:pb-6
`

export const Inner = tw.div`
  max-w-63 mx-auto px-2-4
`

export const Title = tw.h1`
  text-35 leading-1.28 tracking-3 md:text-70 md:leading-1.07 md:tracking-2 font-bold uppercase max-w-30 md:max-w-55-5 mx-auto text-center mb-1-2 md:mb-3-6
`

export const ImageWrapper = tw.div`
  max-w-24 md:max-w-40 mx-auto mt-9-3 md:mt-15-2 z-10 relative
`

export const Timeline = styled.div`
  ${tw`hidden md:block absolute w-0-2 top-1/3 bottom-0 bg-red left-1/2 right-1/2`}
  &:before {
    content: "";
    ${tw`w-1-6 h-1-6 absolute -top-0-7 -left-0-7 border border-red rounded-full bg-red`}
  }
`
