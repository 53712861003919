import React from "react"

export const withSlider = Component => ({ name = "Slider", layout, prevEl = "", nextEl = "", ...rest }) => {
  const navigation = {
    prevEl,
    nextEl,
  }

  const config = {
    "product-carousel-3": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        415: {
          slidesPerView: 1.4,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
    "product-carousel-4": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        415: {
          slidesPerView: 1.4,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    product: {
      slidesPerView: 1.2,
      spaceBetween: 0,
      breakpoints: {
        415: {
          slidesPerView: 1.6,
        },
        769: {
          slidesPerView: 2.5,
          spaceBetween: 40,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 120,
        },
      },
    },
    cartProduct: {
      slidesPerView: 1.3,
      spaceBetween: 0,
      breakpoints: {
        415: {
          slidesPerView: 2.2,
        },
        1025: {
          slidesPerView: 3.4,
          spaceBetween: 16,
        },
      },
    },
    article: {
      slidesPerView: 1.1,
      spaceBetween: 0,
      breakpoints: {
        415: {
          slidesPerView: 2.2,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
    history: {
      slidesPerView: 1.2,
      spaceBetween: 0,
      breakpoints: {
        768: {
          spaceBetween: 28,
        },
      },
    },
    instagram: {
      slidesPerView: 2.5,
      spaceBetween: 16,
      breakpoints: {
        768: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    search: {
      slidesPerView: 1.6,
      spaceBetween: 0,
      breakpoints: {
        768: {
          slidesPerView: 2.4,
          spaceBetween: 30,
        },
      },
    },
    "imageContentCarousel-3": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    },
    "imageContentCarousel-4": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    },
    "imageContentCarousel-5": {
      slidesPerView: 1.2,
      spaceBetween: 16,
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1025: {
          slidesPerView: 5,
          spaceBetween: 30,
        },
      },
    },
  }

  Component.displayName = name
  return (
    <Component
      navigation={navigation}
      breakpoints={config[layout]?.breakpoints}
      spaceBetween={config[layout].spaceBetween}
      slidesPerView={config[layout].slidesPerView}
      {...rest}
    />
  )
}
