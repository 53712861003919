import React from "react"

import { useImage } from "../../../hooks/useImage"
import { useRoutes } from "../../../hooks/useRoutes"

export const withEndSection = Component => ({ name = "EndSection", item }) => {
  const { getFluidImage } = useImage()
  const { linkResolver } = useRoutes()

  const { title, content, image: originalImage, link: originalLink } = item

  const image = getFluidImage(originalImage, { maxWidth: 800 })
  const link = linkResolver(originalLink)

  Component.displayName = name
  return <Component image={image} year={title} content={content} link={link} />
}
