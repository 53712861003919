import React from "react"

import { withBadge } from "./withBadge"
import { Icon } from "../Icon/Icon"
import { PositionWrapper } from "./BadgeStyles"

export const Badge = withBadge(({ left, top, size, icon, iconBadge, rotate }) => (
  <PositionWrapper left={left} top={top} rotate={rotate}>
    {iconBadge && <img src={iconBadge.src} width={size} height={size} />}
    {icon && !iconBadge && <Icon name={icon} width={size} height={size} />}
  </PositionWrapper>
))
