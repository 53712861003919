import React from "react"

export const withHistorySection = Component => ({ name = "HistorySection", item, index }) => {
  const { year, content, images } = item

  const imagePosition = index % 2 === 0 ? "left" : "right"

  const isSlider = images?.length > 1 ? true : false

  Component.displayName = name
  return <Component id={index} year={year} content={content} images={images} position={imagePosition} isSlider={isSlider} />
}
