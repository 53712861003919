import tw, { styled } from "twin.macro"

export const PositionWrapper = styled.div<PositionWrapperProps>`
  ${tw`absolute bg-beige-light p-0-6 md:p-1 rounded-full z-20 text-red`}
  left: ${({ left }) => (left ? `${left}%` : `0`)};
  top:  ${({ top }) => (top ? `${top}%` : `0`)};
  transform: ${({ rotate, left }) => `${rotate ? `rotate(${rotate}deg)` : ""} ${left == 50 ? `translateX(-50%)` : ""}`}
`

type PositionWrapperProps = {
  left: number
  top: number
  rotate: number
}
