import React from "react"

import { withStartSection } from "./withStartSection"
import { Outer, Inner, Title, ImageWrapper, Timeline } from "./StartSectionStyles"
import { RichText } from "../../RichText/RichText"
import { Image } from "../../Image/Image"
import { Badge } from "../../Badge/Badge"

export const StartSection = withStartSection(({ title, content, image, badge }) => (
  <Outer>
    {badge.icon ? <Badge badge={badge} /> : null}
    <Inner>
      <Title>{title}</Title>
      <RichText align={"center"}>{content}</RichText>
      <ImageWrapper>
        <Image image={image} />
      </ImageWrapper>
      <Timeline />
    </Inner>
  </Outer>
))
