import tw, { styled } from "twin.macro"

export const Wrapper = styled.div<PositionProps>`
  ${tw`py-2 md:py-6 flex flex-col relative`}
  ${({ position }) => (position === "left" ? tw`md:flex-row` : tw`md:flex-row-reverse`)}
`

export const ImagesWrapper = styled.div<ImagesWrapperProps>`
  ${tw`w-full md:w-1/2 relative mb-4 md:mb-0`}
  ${({ isSlider }) => (isSlider === "true" ? null : tw`px-2-4`)}
  ${({ position }) => (position === "left" ? tw`md:pl-0 md:pr-3-2` : tw`md:pr-0 md:pl-3-2`)}
`

export const ImageWrapper = tw.div`
  pl-2-4 md:px-0
`

export const Next = styled.button<PositionProps>`
  ${({ position }) => (position === "left" ? tw`md:right-5-6` : tw`md:right-2-4`)}
  ${tw`absolute top-1/2 text-beige right-1-2 z-20`}
  &.swiper-button-disabled {
    ${tw`opacity-0 invisible pointer-events-none`}
  }
  span {
    svg {
      ${tw`w-1-6 h-1-6 md:w-3-2 md:h-3-2`}
    }
  }
`

export const Prev = styled.button<PositionProps>`
  ${({ position }) => (position === "left" ? tw`md:left-2-4` : tw`md:left-5-6`)}
  ${tw`absolute top-1/2 text-beige left-1-2 z-20`}
  &.swiper-button-disabled {
    ${tw`opacity-0 invisible pointer-events-none`}
  }
  span {
    svg {
      ${tw`w-1-6 h-1-6 md:w-3-2 md:h-3-2`}
    }
  }
`

export const ContentOuter = styled.div<PositionProps>`
  ${({ position }) => (position === "left" ? tw`md:justify-start md:pl-10 md:pr-0` : tw`md:justify-end md:pr-10 md:pl-0`)}
  ${tw`px-2-4 w-full md:w-1/2 flex items-center`}
`

export const ContentInner = styled.div<PositionProps>`
  ${tw`max-w-30 relative`}
  &:before {
    content: "";
    ${({ position }) => (position === "left" ? tw`-left-11` : tw`-right-11`)}
    ${tw`hidden md:block w-2 h-2 border border-red rounded-full bg-red absolute top-2`}
  }
`

export const Year = styled.h3<PositionProps>`
  ${({ position }) => (position === "left" ? tw`text-left` : tw`md:text-right`)}
  ${tw`text-red text-50 leading-1.2 tracking-2 font-bold mb-2-4 relative`}
  &:before {
    content: "";
    ${({ position }) => (position === "left" ? tw`-left-6-4` : tw`-right-6-4`)}
    ${tw`hidden md:block w-4 h-0-2 bg-red absolute top-1/2`}
  }
`

export const Timeline = styled.div`
  ${tw`hidden md:block absolute w-0-2 top-0 bottom-0 bg-red left-1/2 right-1/2`}
`

type PositionProps = {
  position: "left" | "right"
}

type ImagesWrapperProps = {
  isSlider: "true" | "false"
  position: "left" | "right"
}
