import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  max-w-lg mx-auto px-2-4 md:px-3 relative flex flex-wrap pt-2 md:pt-23-4 pb-4 md:pb-16 
`

export const ImageWrapper = tw.div`
  hidden md:block md:w-1/2 md:pr-7-2
`

export const ContentOuter = tw.div`
  w-full md:w-1/2 md:pl-7-2 flex flex-col justify-start
`

export const ContentInner = tw.div`
  mb-2-4 md:mb-3-2
`

export const Year = tw.h3`
  text-50 leading-1.2 tracking-2 font-bold mb-2-4 text-red uppercase
`

export const Timeline = styled.div`
  ${tw`hidden md:block absolute w-0-2 top-0 bottom-9/10 bg-red left-1/2 right-1/2`}
  &:before {
    content: "";
    ${tw`w-2 h-2 absolute -bottom-0-9 -left-0-9 border border-red rounded-full bg-red`}
  }
`
