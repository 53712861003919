import React from "react"
import { SwiperSlide } from "swiper/react"

import { withHistorySection } from "./withHistorySection"
import { Wrapper, ImagesWrapper, Next, Prev, ContentOuter, ContentInner, Year, Timeline, ImageWrapper } from "./HistorySectionStyles"
import { Slider } from "../../Slider/Slider"
import { ImageVideo } from "../../ImageVideo/ImageVideo"
import { Icon } from "../../Icon/Icon"
import { RichText } from "../../RichText/RichText"

export const HistorySection = withHistorySection(({ id, year, content, images, position, isSlider }) => (
  <Wrapper position={position}>
    <ImagesWrapper isSlider={isSlider ? "true" : "false"} position={position}>
      {isSlider ? (
        <>
          <Slider layout={"history"} prevEl={`#history-${id}-prev`} nextEl={`#history-${id}-next`}>
            {images.map(image => (
              <SwiperSlide key={image._key}>
                <ImageWrapper>
                  <ImageVideo imageVideo={image} ratio={"8/7"} />
                </ImageWrapper>
              </SwiperSlide>
            ))}
          </Slider>
          <Prev position={position} id={`history-${id}-prev`}>
            <Icon name={"arrowLeft"} />
          </Prev>
          <Next position={position} id={`history-${id}-next`}>
            <Icon name={"arrow"} />
          </Next>
        </>
      ) : (
        <ImageVideo imageVideo={images ? images[0] : null} ratio={"5/3"} />
      )}
    </ImagesWrapper>
    <ContentOuter position={position}>
      <ContentInner position={position}>
        <Year position={position}>{year}</Year>
        <RichText align={position === "left" ? "left" : "md:right"}>{content}</RichText>
      </ContentInner>
    </ContentOuter>
    <Timeline />
  </Wrapper>
))
