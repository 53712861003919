import React from "react"

import { useWindowSize } from "../../hooks/useWindowSize"
import { useImage } from "../../hooks/useImage"

export const withBadge = Component => ({ name = "Badge", badge }) => {
  const { getFluidImage } = useImage()
  const { windowSize } = useWindowSize()

  // icon is supported for legacy purposes. iconBadge is the active user defined data type.
  const { horizontalDesktop, horizontalMobile, verticalDesktop, verticalMobile, rotate, icon } = badge
  const iconBadge = badge.iconBadge?.image ? getFluidImage(badge.iconBadge.image) : null

  const left = windowSize.width > 768 ? horizontalDesktop : horizontalMobile
  const top = windowSize.width > 768 ? verticalDesktop : verticalMobile
  const size = windowSize.width > 768 ? 150 : 93

  Component.displayName = name
  return <Component left={left} top={top} size={size} rotate={rotate} icon={icon} iconBadge={iconBadge} />
}
