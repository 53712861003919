import React from "react"

import { useImage } from "../../hooks/useImage"

export const withAbout = Component => ({ name = "About", page }) => {
  const { getFluidImage } = useImage()

  const { startTitle, startContent, startImage, badge, sections, endTitle, endContent, endLink, endImage, image: originalImage } = page

  const start = {
    title: startTitle,
    content: startContent,
    image: startImage,
    badge,
  }

  const end = {
    title: endTitle,
    content: endContent,
    image: endImage,
    link: endLink,
  }

  const image = getFluidImage(originalImage, { maxWidth: 1200 })

  Component.displayName = name
  return <Component start={start} end={end} sections={sections} image={image} />
}
