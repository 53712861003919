import React from "react"
import { graphql } from "gatsby"

import { About as Page } from "../components/About/About"

export const query = graphql`
  query {
    page: sanityPageAbout {
      startTitle
      startContent: _rawStartContent(resolveReferences: { maxDepth: 2 })
      startImage: _rawStartImage(resolveReferences: { maxDepth: 3 })
      badge: _rawBadge(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 5 })
      endTitle
      endContent: _rawEndContent(resolveReferences: { maxDepth: 2 })
      endLink: _rawEndLink(resolveReferences: { maxDepth: 2 })
      endImage: _rawEndImage(resolveReferences: { maxDepth: 3 })
      image: _rawImage(resolveReferences: { maxDepth: 3 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
