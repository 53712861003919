import React from "react"

import { withAbout } from "./withAbout"
import { Wrapper } from "./AboutStyles"
import { StartSection } from "./StartSection/StartSection"
import { HistorySection } from "./HistorySection/HistorySection"
import { EndSection } from "./EndSection/EndSection"
import { Image } from "../Image/Image"

export const About = withAbout(({
  start,
  end,
  sections,
  image,
}) => (
    <Wrapper>
      <StartSection item={start} />
      {sections.map((section, index) => <HistorySection key={section._key} item={section} index={index} />)}
      <EndSection item={end} />
      <Image image={image} />
    </Wrapper>
  ))