import React from "react"
import { Link } from "gatsby"

import { withEndSection } from "./withEndSection"
import { Wrapper, ImageWrapper, ContentOuter, ContentInner, Year, Timeline } from "./EndSectionStyles"
import { Image } from "../../Image/Image"
import { RichText } from "../../RichText/RichText"
import { StyledButton } from "../../Styled/Button"

export const EndSection = withEndSection(({ image, year, content, link }) => (
  <Wrapper>
    <ImageWrapper>
      <Image image={image} />
    </ImageWrapper>
    <ContentOuter>
      <Year>{year}</Year>
      <ContentInner>
        <RichText>{content}</RichText>
      </ContentInner>
      {link?.url ? <div><StyledButton as={Link} to={link?.url} size={"tertiary"} colour={"red"}>{link?.title}</StyledButton></div> : null}
    </ContentOuter>
    <Timeline />
  </Wrapper>
))